function SupportFC() {
    return (
        <div className="App">
            <header className="App-header">
                Donating and stuff
            </header>
        </div>
    );
}
export default SupportFC;
