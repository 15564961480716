import('../Styles/LongTextPage.css')
function LongForm(HeadingIcon, HeadingText, InnerContent) {
    return (

        <main role="main" id="LongForm">
            <div className="container page-bg">
                <div className={"lt-icon " + HeadingIcon}></div>
                <span className={"text-container"}>{HeadingText}</span>
            </div>
            <div className="content-main">
                {InnerContent}
            </div>
        </main>
    );
}
export default LongForm;
