import LongForm from "../Components/LongFormContent";

function TermsOfUse() {
    return LongForm('pp-icon', 'Terms Of Use',
        <div className="container">
            <div className="row">
                <div className="col-lg-12 m-1">

                </div>
            </div>
        </div>
    );
}
export default TermsOfUse;
