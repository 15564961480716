import LongForm from "../Components/LongFormContent";
import React from "react";

export default function LandingPage(data) {

    if(data.page === "minecraft_invalidcode") {
        return InvalidCode();
    }
    if(data.page === "minecraft_alreadylinked") {
        return AlreadyLinked();
    }
    if(data.page === "member_missing") {
        return MissingMember();
    }
    if(data.page === "minecraft_linked") {
        return MinecraftLinked();
    }
    if(data.page === "invite_success") {
        return InviteComplete();
    }
    if(data.page === "twitch_linked") {
        return ContentLinked();
    }
    if(data.page === "youtube_linked") {
        return ContentLinked();
    }


}

function InviteComplete() {
    return LongForm('missing-member', "Account Created!",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3 className="text-success text-capitalize">Welcome!</h3>
                    <p>Your account has successfully been linked to the FusionCraft portal. You can now visit our discord and join our servers!
                        <br />
                        <strong>Now get gaming!</strong>
                    </p>
                </div>
            </div>
        </div>
    );
}


function ContentLinked() {
    return LongForm('content-linked', "Account Linked!",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-creator-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3 className="text-success text-capitalize">Yay! It worked</h3>
                    <p>Your account has successfully been linked to the FusionCraft portal. New content on your channel will be automatically advertised in our discord, and on our website!
                        <br />
                    </p>
                </div>
            </div>
        </div>
    );
}

function MinecraftLinked() {
    return LongForm('missing-member', "Account Linked!",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3 className="text-success text-capitalize">Yay! It worked</h3>
                    <p>Your account has successfully been linked to the FusionCraft portal.
                        <br />
                        <strong>Now get gaming!</strong>
                    </p>
                </div>
            </div>
        </div>
    );
}

function MissingMember() {
    return LongForm('missing-member', "Account Missing",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3>Uh, there is a problem..</h3>
                    <p>We were not able find your account in our system. You must be invited to FusionCraft before you can link your account.</p>
                </div>
            </div>
        </div>
    );
}

function AlreadyLinked() {
    return LongForm('already-linked', "Already Linked",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3>Uh, oh no!</h3>
                    <p>You already have a minecraft account linked. If you want to change which account is linked, reach out for support on the FusionCraft Discord.</p> </div>
            </div>
        </div>
    );
}

function InvalidCode() {
    return LongForm('invalid-message', "Invalid Code",
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3">
                    <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                </div>
                <div className="col-md-8">
                    <h3>Uh, oh no!</h3>
                    <p>The link code you used was not valid! If you think this is a mistake reach out for support on the FusionCraft Discord!</p>
                </div>
            </div>
        </div>
    );
}