import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from "./Components/Header";
import Home from "./Pages/Homepage";
import Footer from "./Components/Footer";
import "bootstrap/dist/css/bootstrap.css";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import FAQ from "./Pages/FAQ";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import SupportFC from "./Pages/SupportFC";
import {positions, Provider as AlertProvider, transitions} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import AboutUs from "./Pages/About";
import RedeemInvite from "./Pages/RedeemInvite";
import RedeemMinecraft from "./Pages/RedeemMinecraft";
import RedeemContentCreator from "./Pages/RedeemContentCreator";
import LandingPage from "./Pages/LandingPage";

// Alert Config
// optional configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.MIDDLE,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.FADE
}

ReactDOM.render(
    <React.StrictMode>
    <Header/>
        <AlertProvider template={AlertTemplate} {...options} >
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="privacy_policy" element={<PrivacyPolicy />} />
                <Route path="terms_of_use" element={<TermsOfUse />} />
                <Route path="support" element={<SupportFC />} />
                <Route path="about" element={<AboutUs />} />


                <Route path="/invite/" element={<RedeemInvite />} />
                <Route path="/invite/:code" element={<RedeemInvite />} />

                <Route path="/mc/" element={<RedeemMinecraft />} />
                <Route path="/mc/:code" element={<RedeemMinecraft />} />
                <Route path="/creator/:platform/:code" element={<RedeemContentCreator />} />


                <Route path="/minecraft/invalid_code" element={<LandingPage page="minecraft_invalidcode" />} />
                <Route path="/minecraft/already_linked" element={<LandingPage page="minecraft_alreadylinked" />} />
                <Route path="/member/missing" element={<LandingPage page="member_missing" />} />
                <Route path="/minecraft/linked" element={<LandingPage page="minecraft_linked" />} />
                <Route path="/invite/successful" element={<LandingPage page="invite_success" />} />
                <Route path="/twitch/linked" element={<LandingPage page="twitch_linked" />} />
                <Route path="/youtube/linked" element={<LandingPage page="youtube_linked" />} />

                <Route
                    path="*"
                    element={
                        <main style={{padding: "1rem", margin:"0 auto", width: "500px", textAlign: "center"}}>
                            <h2>404</h2>
                            <h5>There's nothing here!</h5>
                        </main>
                    }
                />
            </Routes>
        </BrowserRouter>
        </AlertProvider>
        <Footer/>
    </React.StrictMode>,
  document.getElementById('root')
);
