import {useMediaQuery} from "react-responsive";

export const DeviceType = {
    MOBILE: "MOBILE",
    TABLET: "TABLET",
    LAPTOP: "LAPTOP",
    DESKTOP: "DESKTOP",
}
export const DetermineType = () => {
    const isMobileDevice = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

    const isTabletDevice = useMediaQuery({
        query: "(max-device-width: 1024px)",
    });

    const isLaptop = useMediaQuery({
        query: "(max-device-width: 1200px)",
    });

    const isDesktop = useMediaQuery({
        query: "(min-device-width: 1200px)",
    });

    if (isMobileDevice) {
        return DeviceType.MOBILE
    }
    if (isTabletDevice) {
        return DeviceType.TABLET;
    }
    if (isLaptop) {
        return DeviceType.LAPTOP;
    }
    return DeviceType.DESKTOP;
}