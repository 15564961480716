import LongForm from "../Components/LongFormContent";

function AboutUs() {
    return LongForm('about-icon', "About FusionCraft",
        <div className="container">
            <div className="row">
                <div className="col-lg-12 m-1">
                    <p>FusionCraft was started by a group of friends who played Minecraft together and wanted a place that they could play together without worrying about whether the server would be well managed or not. So they got together and formed FusionCraft as an invite only community. Over time this evolved to include other games as members of the community started to want to play other games with their friends.</p>
                    <p>Luckily one of the members of this group had extensive experience with running cloud services, networking, and setting up gaming networks. He volunteered to host and manage the platform for the community and works hard to ensure that it's stable, secure, and running well. Over the past year the Admin and the Community Leaders have been working to evolve the platform the community uses to include automation and scaling the resources as demand changes. This is going to help us all by making it easier to invite friends, and also easier to add other games as people find new games they want to play together.</p>
                    <p>Lots of exciting updates and changes coming!</p>
                    <p>We always want to hear feedback from the community. If you ever have ideas, concerns, or just want to share some thoughts we'd love to hear it. Feel free to contact one of the Community Leaders any time to chat.</p>
                </div>
            </div>
        </div>
    );
}
export default AboutUs;
